import { LoadingPage } from '@koble/koble-ui-lib';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useKobleContext } from '~/hooks/kobleContext';

const Index: NextPage = () => {
  const { isPending, userLoading, user } = useKobleContext();
  const router = useRouter();

  useEffect(() => {
    if (!isPending && !userLoading && user) {
      if (user.organisation.activatedOn !== null) {
        router.replace('/lists');
      } else {
        router.replace('/onboarding');
      }
    }
  }, [isPending, userLoading, user]);
  return <LoadingPage />;
};

export default Index;
